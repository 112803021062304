<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo" :to="{ name: 'login' }">
        <artos-logo />

        <h2 class="brand-text text-primary ml-1">
          OBIZZ.
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="sideImg"
            alt="Illustration of Signup"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ this.$t('pages.signup.verificationHeader') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ this.$t('pages.signup.verificationText') }}
          </b-card-text>

          <p>
            <span>{{ this.$t('pages.signup.dontGetEmail') }}</span>
            <br>
            <span>{{ this.$t('pages.signup.checkAgain') }}</span>
          </p>
          <p>
            <b-button
              block
              :variant="`outline-${isDisabled ? 'secondary' : 'primary'}`"
              :disabled="isDisabled"
            >
              {{ `${countDown}` }}
              <feather-icon v-if="!isDisabled" icon="RotateCcwIcon" />
              <b-spinner
                  small
                  type="grow"
                  v-if="!isDisabled && isLoading"
                />
              {{ this.$t('pages.signup.actions.sendAgain') }}
            </b-button>
          </p>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import ArtosLogo from '@/components/logo/Logo.vue'
import {
  BRow, BButton, BSpinner, BCol, BLink,  BImg, BCardTitle, BCardText
} from 'bootstrap-vue'
import { computed, onMounted, ref } from '@vue/composition-api'

export default {
  props: {
    form: {
      type: Object
    }
  },
  components: {
    ArtosLogo,
    BRow,
    BButton,
    BSpinner,
    BImg,
    BCol,
    BLink,
    BCardTitle,
    BCardText
  },
  setup (props) {
    const isDisabled = ref(true)
    const isLoading = ref(false)

    const sideImg = ref(require('@/assets/images/pages/signup/success-register.svg'))

    const duration = ref(60 * 2) // 2 minutes
    const handleCoundDown = () => {
      if (duration.value > 0) {
        duration.value--
      } else {
        duration.value = null
        isDisabled.value = false
        clearInterval(duration.value)
      }
    }

    const countDown = computed(() => {
      const minutes =  String(Math.floor((duration.value / 60))).padStart(2, 0)
      const seconds = String(Math.floor((duration.value % 60))).padStart(2, 0)
      if (minutes === '00' && seconds === '00') {
        return ''
      } else {
        return `${minutes}:${seconds}`
      }
    })

    onMounted(() => {
      console.log(props.form)
      setInterval(handleCoundDown, 1000)
    })

    return {
      isLoading,
      isDisabled,
      sideImg,
      countDown
    }
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
